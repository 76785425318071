import '../styles/index.scss'; // webpackでコンパイルするため必須. 実際には.jsには展開されない.

$(document).ready(() => {

  // 検索フォームのクリア
  $('.js-clearBtn').on('click', () => {
    $('.js-searchForm')
      .find('input, select')
      .val('')
      .prop('checked', false)
      .prop('selected',false);
  });
});
